import axios from 'axios'
import { Fragment } from 'react'
import { toast } from 'react-toastify'
import constants from '../constants'
import { store } from '../redux/storeConfig/store'

export const ErrorToastComponent = (props) => {
  const { title, message } = props
  return (
    <Fragment>
      <div className="toastify-header pb-0">
        <div className="title-wrapper user-select-none">
          {/* <Avatar size='sm' color={color} icon={icon} /> */}
          <h6 className="toast-title">{message}</h6>
        </div>
      </div>
    </Fragment>
  )
}

// axios.defaults.timeout = 120000

// axios.defaults.baseURL = process.env.NODE_ENV === "development" ? 'https://20f5-2405-201-2002-2c4a-4804-8ea0-d323-efa6.in.ngrok.io' : constants.appBaseUrl

axios.defaults.baseURL = constants.appBaseUrl

const delay = async (milliseconds) => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(true)
    }, milliseconds)
  })
}

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const headers = {
      Accept: 'application/json'
    }

    const { selectedManager, selectedParentManager} = store.getState().managers
  
    if (selectedParentManager?.access_token) {
      headers['Authorization'] = `Bearer ${selectedParentManager?.access_token}`
    } else {
      const accessToken =
      selectedManager?.access_token || localStorage.getItem('accessToken')
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`
      }
    }
    

    config.headers = {
      ...headers,
      ...config.headers
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const { status, data } = error.response

    toast.error(
      <ErrorToastComponent
        title={'Error'}
        message={data?.message || 'Something went wrong!'}
      />,
      {
        autoClose: 1500,
        hideProgressBar: true,
        closeButton: true
      }
    )

    if (status === 401) {
      await delay(2000)
      // redirect to login
      localStorage.removeItem('accessToken')
      window.location = '/'
    }

    return Promise.reject(error)
  }
)

export default axios
