import Manager from "../../../Modal/Manager"
import {
    GET_MANAGERS,
    SELECT_MANAGER,
    SELECT_PARENT_MANAGER,
    TOGGLE_MANAGERS_LOADER,
    GET_MANAGER_DATATABLE_DATA,
    GET_BLIPP_REGION_LIST,
    GET_SUB_MANAGER_TOKEN,
    GET_PARENT_MANAGER_TOKEN,
    GET_AST_PARENT_MANAGER_LIST
} from "../../actions/managers"

const initialState = {
    isLoading: false,
    managers: [],
    selectedManager: null,
    blippRegions: [],
    datatable: {
        data: [],
        total: 1
    }
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_MANAGERS:
            const managers = Array.isArray(payload) && payload.length ? action.payload.map(managerObj => new Manager(managerObj)) : []
            return {
                ...state,
                selectedManager: managers[0],
                managers
            }
        case GET_AST_PARENT_MANAGER_LIST:
            const parnetManagers = Array.isArray(payload) && payload.length ? action.payload.map(managerObj => new Manager(managerObj)) : []
             return {
                ...state,
                selectedParentManager: parnetManagers[0],
                parnetManagers
            }
        case TOGGLE_MANAGERS_LOADER:
            return {
                ...state,
                isLoading: !state.isLoading
            }
        case GET_SUB_MANAGER_TOKEN:
            
            const managerIndex = state.managers.findIndex(manager => parseInt(manager?.admin_register_master_id) === parseInt(payload?.admin_register_master_id))

            state.managers[managerIndex] = new Manager(payload)

            return {
                ...state,
                managers: [...state.managers]
            }
        case GET_PARENT_MANAGER_TOKEN:
            
            const parentManagerIndex = state.parnetManagers.findIndex(manager => parseInt(manager?.admin_register_master_id) === parseInt(payload?.admin_register_master_id))

            state.parnetManagers[parentManagerIndex] = new Manager(payload)

            return {
                ...state,
                managers: [...state.managers]
        }
        case SELECT_MANAGER:
            const selectedManager = state.managers.find(manager => manager.admin_register_master_id === payload.admin_register_master_id) ?? null
            return {
                ...state,
                selectedManager
            }
        case SELECT_PARENT_MANAGER:  
            const selectedParentManager = state.parnetManagers.find(manager => manager.admin_register_master_id === payload.admin_register_master_id) ?? null
           
            return {
                ...state,
                selectedParentManager
            }
        case GET_MANAGER_DATATABLE_DATA:
            return {
                ...state,
                datatable: {
                    data: payload.data,
                    total: payload.totalPages,
                    params: payload.params
                }
            }
        case GET_BLIPP_REGION_LIST:
            return {
                ...state,
                blippRegions: payload
            }
        case 'LOGOUT':
            return initialState
        default:
            return state
    }
}