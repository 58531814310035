import { SELECT_MANAGER, SELECT_PARENT_MANAGER } from '../../actions/managers'
import constants from '@src/constants'

// **  Initial State
const initialState = {
  userData: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: {
          ...action.data,
          is_admin: action.data.role === constants.roles.admin,
          is_manager: action.data.role === constants.roles.manager,
          is_asst_manager: action.data.role === constants.roles.asst_manager
        }
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'SET_UP_DATA':
      return {
        ...state,
        userData: {
          ...action.data,
          is_admin: action.data.role === constants.roles.admin,
          is_manager: action.data.role === constants.roles.manager,
          is_asst_manager: action.data.role === constants.roles.asst_manager
        }
      }
    case 'LOGOUT':
      // const obj = { ...action }
      // delete obj.type
      // return { ...state, userData: {}, ...obj }
      return initialState
    case SELECT_MANAGER:
      const updatedVal = {
        ...state,
        userData: {
          ...state.userData,
          token: action.payload.token,
          user_master_id: action.payload.user_master_id
        }
      }      
      return updatedVal
    case SELECT_PARENT_MANAGER:     
        const updatedManagerVal = {
          ...state,
          userData: {
            ...state.userData,
            token: action.payload.token,
            user_master_id: action.payload.user_master_id
          }
        }              
        return updatedManagerVal
    default:
      return state
  }
}

export default authReducer
